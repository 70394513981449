import React, { useContext } from 'react';
import $ from 'jquery';
import { LanguageContext } from '../../contexts/LanguageContext/LanguageContext';
import eventEmitter from '../../events/EventEmitter';
import brFlag from './flags/br.png';
import ptFlag from './flags/pt.png';
import caFlag from './flags/ca.png';

const flags = {
  'pt-BR': brFlag,
  'pt-PT': ptFlag,
  'en-CA': caFlag,
};

const LanguageSwitcher = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  const handleClick = (lang) => {
    setLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);

    eventEmitter.emit('LanguageSwitcher', 'lang-updated', lang);
  };

  const stylesLanguageSwitcher = {
      position: 'fixed',
      top: '-5px',
      right: '10px',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
  };

  return (
    <div style={stylesLanguageSwitcher} >
      {Object.keys(flags).map((lang) => (
        <img
          key={lang}
          src={flags[lang]}
          alt={lang}
          onClick={() => handleClick(lang)}
          style={{
            top: '3px',
            width: language === lang ? '45px' : '30px',
            height: language === lang ? '45px' : '30px',
            cursor: 'pointer',
            border: 'none',
            transition: 'transform 0.3s ease',
          }}
        />
      ))}
    </div>
  );
};

export default LanguageSwitcher;