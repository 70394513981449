class EventEmitter {
    constructor() {
        this.events = {};
    }

    subscribe(component, event, listener) {
        if (!this.events[component + '_' + event]) {
            this.events[component + '_' + event] = [];
        }
        this.events[component + '_' + event].push(listener);
        
        return () => {
            this.events[component + '_' + event] = this.events[component + '_' + event].filter((l) => l !== listener);
        };
    }

    emit(component, event, data) {
        if (this.events[component + '_' + event]) {
            this.events[component + '_' + event].forEach((listener) => listener(data));
        }
    }
}

export default new EventEmitter();  