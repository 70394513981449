/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext, useState, useEffect } from 'react';
import RedirectComponent from '../components/RedirectComponent';
import { LanguageContext } from '../contexts/LanguageContext/LanguageContext';

const HomePage = () => {
  const [redirect, setRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const { texts } = useContext(LanguageContext);

  const handleRedirect = () => {
    setRedirectUrl('https://resume.about-me.cloud/alfgomes/');
    setRedirect(true);
  };

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <h1 css={styles.heading}>{texts.title}</h1>
        <p css={styles.paragraph}>{texts.description}</p>
        <details css={styles.details}>
          <summary css={styles.summary}>{texts.system.titleProjects}</summary>
          <ul css={styles.projectList}>
            {texts.projects.map((project, index) => (
              <li key={index}>
                <p>{project.name}</p>
                {/* <p style={stylesProjects.section.item.year}>{project.year}</p>
                <p style={stylesProjects.section.item.description}>{project.description}</p> */}
              </li>
            ))}
          </ul>
        </details>
        <button css={styles.button} onClick={handleRedirect}>{texts.system.buttonResume}</button>

        {redirect && <RedirectComponent url={redirectUrl} delay={3000} />}
      </div>
    </div>
  );
};

// Estilos usando Emotion CSS-in-JS
const styles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 90vh;
    margin: 40px auto;
    background: linear-gradient(to bottom, #3a6073, #16222a);
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
  `,
  content: css`
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 800px;
  `,
  heading: css`
    font-size: 2em;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
  `,
  paragraph: css`
    font-size: 1.2em;
    margin-bottom: 20px;
  `,
  details: css`
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 10px;
    text-align: left;
    margin-bottom: 20px;
  `,
  summary: css`
    cursor: pointer;
    font-size: 1.2em;
    font-weight: bold;
  `,
  projectList: css`
    margin-top: 10px;
    padding-left: 20px;
    list-style-type: disc;
  `,
  button: css`
    background-color: #ff5f6d;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ff1e36;
    }
  `,
};

const stylesProjects = {
  section: {
    item: {
      marginBottom: '40px',
      borderBottom: '0px solid #ccc',
      cursor: 'default',
      details: {
      },
      summary: {
        pointerEvents: 'none', /* prevents click events */
        userSelect: 'none', /* prevents text selection */
      },
      title: {
        fontFamily: 'Arial',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textShadow: '2px 2px 0 rgba(90, 90, 90, 1.0)',
        float: 'left',
        textAlign: 'left',
      },
      year: {
        fontFamily: 'Arial',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textShadow: '2px 2px 0 rgba(90, 90, 90, 1.0)',
        float: 'right',
        textAlign: 'right',
      },
      description: {
        fontFamily: 'Arial',
        fontSize: '1.2rem',
        fontWeight: 'normal',
        float: 'left',
        textAlign: 'left',
      },
      icon: {
        backgroundColor: '#FFFFFF',
        width: '70px',
        height: '70px',
        marginRight: '0px',
        boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
      },
      a: {
        color: '#ffffff',
        textDecoration: 'none',
        cursor: 'pointer',
        fontFamily: 'Arial',
        fontSize: '0.9rem',
        fontWeight: 'normal',
        float: 'right',
        textAlign: 'right',
      },
    }
  },
};

export default HomePage;