import React, { createContext, useState, useEffect } from 'react';
import enCA from './data/en-CA';
import ptBR from './data/pt-BR';
import ptPT from './data/pt-PT';

export const LanguageContext = createContext();

const languageData = {
  'en-CA': enCA,
  'pt-BR': ptBR,
  'pt-PT': ptPT,
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en-CA');
  const [texts, setTexts] = useState(languageData[language]);

  useEffect(() => {
    setTexts(languageData[language]);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, texts }}>
      {children}
    </LanguageContext.Provider>
  );
};