const ptBR = {
  title: "Bem-vindo ao site de André Gomes",
  description: "Sou um desenvolvedor apaixonado por tecnologia, especializado em ReactJS.",
  resume: "Currículo",
  baseLanguage: "pt",

  system: {
    titleProjects: "Alguns Projetos",
    titleSkills: "Habilidades",
    buttonResume: "Visite meu Currículo",
  },
  
  aboutMe: {
    name: "André",
    photo: "",
    email: "alfgomes@outlook.com",
    phone: "+1 (438) 465-9624",
    localization: "Montréal / QC",
    title: "<p><b>Desenvolvedor de software com experiência internacional.</b></p>",
    description: "<p>Atuo na indústria de tecnologia desde <spam style='color: #2ab0ff;'>2002</spam>, trazendo uma combinação única de experiência e inovação ao mercado. Brasileiro de nascimento e também cidadão português, sou um empreendedor autodidata com uma paixão insaciável por tecnologia e novos desafios!</p>" +
      "<p>Com uma certificação do Collège National de Science et Technologie em Montreal, tenho experiência prática em diversas linguagens de programação. Minha abordagem é focada na resolução eficiente de problemas e na criação de código limpo e funcional, garantindo soluções tecnológicas que atendam às necessidades dos meus clientes.</p>" +
      "<p>Com vários anos de experiência no setor de Tecnologia da Informação, aprimorei minha expertise em várias facetas do desenvolvimento de software e estou confiante de que posso contribuir efetivamente para sua equipa.</p>" +
      "<p>" +
      "Ganhei ampla experiência em Agile e alguns ambientes DevOps, entregando consistentemente soluções de software de alta qualidade. Sou proficiente na criação de pipelines de CI/CD, garantindo implantações suaves e eficientes, e desenvolvi e mantive com sucesso arquiteturas de microsserviços. Isso me permitiu não apenas construir aplicativos escaláveis, mas também melhorar a agilidade geral e a capacidade de resposta dos processos de desenvolvimento.<br/>" +
      "Além disso, tenho fortes habilidades de programação em <spam style='color: #2ab0ff;'>C#</spam> e um entendimento abrangente de tecnologias front-end, como <spam style='color: #2ab0ff;'>Angular</spam>, <spam style='color: #2ab0ff;'>ReactJS</spam>, <spam style='color: #2ab0ff;'>JavaScript</spam>, <spam style='color: #2ab0ff;'>CSS</spam> e <spam style='color: #2ab0ff;'>HTML</spam>. Essa combinação me permitiu fornecer soluções full-stack que atendem às necessidades técnicas e comerciais. Minha capacidade de traduzir requisitos comerciais em especificações funcionais garante que eu contribua para a construção de produtos que se alinham com as metas comerciais. Além disso, tenho alguma experiência prática com <spam style='color: #2ab0ff;'>Python</spam>, o que me permitiu para trabalhar efetivamente em uma variedade de tarefas de back-end, adicionando mais flexibilidade ao meu conjunto de habilidades técnicas. Minha capacidade de utilizar <spam style='color: #2ab0ff;'>Python</spam> para scripts e automação fortalece ainda mais minha capacidade de dar suporte a práticas de DevOps e aumentar a eficiência operacional.<br/>" +
      "Além da minha expertise técnica, eu me mantenho atualizado sobre as últimas tecnologias, sistemas e tendências de mercado, o que me permite adaptar-me rapidamente a novos desafios e entregar continuamente soluções inovadoras. Estou confiante de que minha experiência, juntamente com meu entusiasmo por aprendizado e crescimento contínuos, me tornam uma ótima opção para sua equipa." +
      "</p>",
  },
  
  projects: [
    {
      id: "343d262e-c945-474e-bc75-e6d7890aa237",
      name: "Integrated Payment Portal",
    },
    {
      id: "13bf93cd-df03-492c-8e55-60ac38f5071c",
      name: "TopSky Tower",
    },
    {
      id: "5e73cb7f-13ea-41d6-9927-05a234576d7e",
      name: "DSI Business Inteligence",
    },
    {
      id: "ce30ca48-2965-4ceb-8289-cb89e35fe35c",
      name: "New Budget",
    },
    {
      id: "ad3564ae-255f-400a-863f-312af7ab4e45",
      name: "Simulador de Crédito Habitacional",
    },
    {
      id: "db42ca90-ef00-4ab0-be4a-3d24d9c730f9",
      name: "SSIS (SmartPlant Materials & SAP Integration System)",
    },
    {
      id: "f6fcc159-c8a5-4275-82b9-651a7296b184",
      name: "Warehouse+ (Sistema de Automação de Controle de Materiais no Armazém)",
    },
    {
      id: "752080fe-3394-449d-aa40-033320242c78",
      name: "e-RIR (Relatório Eletrônico de Inspeção e Recebimento)",
    },
    {
      id: "f6a3b64a-d3bc-4b3f-8b0a-0e5629191a36",
      name: "LASA Novas Etiquetas de Separação",
    },
  ],
  
  skills: [
    { name: "Analytic Problem Solving", versions: "", image: "problem-solving-ability.png", link: "" },
    { name: "Analytical Skills", versions: "", image: "analytical-skills.png", link: "" },
    { name: "AngularJS", versions: "", image: "angular.svg", link: "" },
    { name: "Application Programming Interfaces (API)", versions: "", image: "api.png", link: "" },
    { name: "Architectural Design", versions: "", image: "architectural-design.png", link: "" },
    { name: "ASPDotNet", versions: "", image: "asp-net.png", link: "" },
    { name: "Business Intelligence (BI)", versions: "", image: "bi.png", link: "" },
    { name: "Cascading Style Sheets (CSS)", versions: "", image: "css.svg", link: "" },
    { name: "Confluence", versions: "", image: "confluence.jpg", link: "" },
    { name: "Creative Problem Solving", versions: "", image: "creative-problem-solving.png", link: "" },
    { name: "Crucible", versions: "", image: "crucible.svg", link: "" },
    { name: "Data Structures", versions: "", image: "data_structure.png", link: "" },
    { name: "Decision-Making", versions: "", image: "decision-making.webp", link: "" },
    { name: "Delphi", versions: "7", image: "delphi.png", link: "" },
    { name: "Design Patterns", versions: "", image: "design-patterns.png", link: "" },
    { name: "Domain-Driven Design (DDD)", versions: "", image: "ddd.jpg", link: "" },
    { name: "DotNet CORE (C#)", versions: "", image: "csharp.svg", link: "dotnet+core+with+C%23" },
    { name: "DotNet Framework (C# and VBDotNet)", versions: "", image: "visual-studio.svg", link: "" },
    { name: "Enterprise Softwares", versions: "", image: "enterprise-softwares.png", link: "" },
    { name: "Entity Framework", versions: "4, 5, and 6", image: "ef.png", link: "" },
    { name: "Extract, Transform, Load (ETL)", versions: "", image: "etl.png", link: "" },
    { name: "Git", versions: "", image: "git.svg", link: "" },
    { name: "GitHub", versions: "", image: "github_light.svg", link: "" },
    { name: "Gitlab", versions: "", image: "gitlab.svg", link: "" },
    { name: "HTML 5", versions: "", image: "html5.svg", link: "" },
    { name: "Interbase", versions: "", image: "interbase.webp", link: "" },
    { name: "Intraweb", versions: "", image: "intraweb.png", link: "" },
    { name: "Java", versions: "6, 11", image: "java.svg", link: "" },
    { name: "JavaScript", versions: "", image: "javascript.svg", link: "" },
    { name: "JavaServer Pages (JSP)", versions: "", image: "jsp.png", link: "" },
    { name: "Jenkins", versions: "", image: "jenkins.png", link: "" },
    { name: "Jira", versions: "", image: "jira.webp", link: "" },
    { name: "JSON", versions: "", image: "json-schema.svg", link: "" },
    { name: "LINQ", versions: "", image: "linq.webp", link: "" },
    { name: "Kendo UI", versions: "", image: "kendo-ui.webp", link: "" },
    { name: "Maven", versions: "", image: "maven.jpg", link: "" },
    { name: "Ms Access", versions: "", image: "microsoft.svg", link: "" },
    { name: "Ms Project", versions: "", image: "microsoft.svg", link: "" },
    { name: "Mockito", versions: "", image: "mockito.png", link: "" },
    { name: "Model-View-Controller (MVC)", versions: "", image: "mvc.png", link: "" },
    { name: "MySQL", versions: "", image: "mysql.svg", link: "" },
    { name: "Node.js", versions: "", image: "nodejs.svg", link: "" },
    { name: "Oracle", versions: "", image: "oracle.png", link: "" },
    { name: "PHP", versions: "", image: "php.svg", link: "" },
    { name: "PL/SQL", versions: "", image: "oracle.png", link: "" },
    { name: "Problem Solving", versions: "", image: "problem-solving.png", link: "" },
    { name: "Quality Assurance", versions: "", image: "qa.png", link: "" },
    { name: "ReactJS", versions: "", image: "react.svg", link: "" },
    { name: "Scrum", versions: "", image: "scrum.png", link: "" },
    { name: "Servlets", versions: "", image: "java.svg", link: "" },
    { name: "Shell Script", versions: "", image: "terminal.png", link: "" },
    { name: "SonarQube", versions: "", image: "sonarqube.png", link: "" },
    { name: "Sourcetree", versions: "", image: "sourcetree.svg", link: "" },
    { name: "SQL DB2", versions: "", image: "db2.svg", link: "" },
    { name: "SQL Server", versions: "2000, 2005, 2008, 2012", image: "sql-server.svg", link: "" },
    { name: "Integration Services (SSIS)", versions: "", image: "sql-server.svg", link: "" },
    { name: "SQL Server Reporting Services (SSRS)", versions: "", image: "sql-server.svg", link: "" },
    { name: "Team Foundation Server (TFS)", versions: "", image: "microsoft.svg", link: "" },
    { name: "Test-Driven Development (TDD)", versions: "", image: "tdd.png", link: "" },
    { name: "TIBCO", versions: "", image: "tibco.png", link: "" },
    { name: "TIBCO Spotfire", versions: "", image: "tibco.png", link: "" },
    { name: "Procedures", versions: "", image: "stored-procedure.png", link: "" },
    { name: "Web API", versions: "", image: "api.png", link: "" },
    { name: "Web Applications", versions: "", image: "code.png", link: "" },
    { name: "Web Services", versions: "", image: "web-services.png", link: "" },
    { name: "Web Services API", versions: "", image: "api.png", link: "" },
    { name: "Window Forms", versions: "", image: "code.png", link: "" },
    { name: "Windows Presentation Foundation (WPF)", versions: "", image: "wpf.png", link: "" },
    { name: "Development", versions: "", image: "vscode.svg", link: "" },
    { name: "Write SQL Scripts", versions: "", image: "sql.png", link: "" },
    { name: "Zebra Programming Language (ZPL)", versions: "", image: "zebra.webp", link: "" },
  ],
};

export default ptBR;