import { useEffect } from 'react';

const RedirectComponent = ({ url, delay }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (url) {
        window.location.href = url; // Redireciona para a URL recebida após o delay
      }
    }, delay);

    return () => clearTimeout(timer); // Limpa o timer quando o componente for desmontado
  }, [url, delay]);

  return null;
};

export default RedirectComponent;