const enCA = {
  title: "Welcome to André Gome's website",
  resume: "Resume",
  description: "I'm a technology-loving developer specializing in ReactJS.",
  baseLanguage: "en",

  system: {
    titleProjects: "Some Projects",
    titleSkills: "Skills",
    buttonResume: "Visit my Resume",
  },

  aboutMe: {
    name: "Andre",
    photo: "",
    email: "alfgomes@outlook.com",
    phone: "+1 (438) 465-9624",
    localization: "Montreal / QC",
    title: "<p><b>Software developer with international experience.</b></p>",
    description: "<p>I have been working in the technology industry since <spam style='color: #2ab0ff;'>2002</spam>, bringing a unique combination of experience and innovation to the market. Brazilian by birth and also a Portuguese citizen, I am a self-taught entrepreneur with an insatiable passion for technology and new challenges!</p>" +
      "<p>With a certification from the Collège National de Science et Technologie at Montreal, I have hands-on experience in several programming languages. My approach is focused on efficient problem-solving and creating clean, functional code, ensuring technological solutions that meet my clients needs.</p>" +
      "<p>With several years of experience in the Information Technology industry, I have honed my expertise in various facets of software development and am confident that I can effectively contribute to your team.</p>" +
      "<p>" +
      "I have gained extensive experience in Agile and some DevOps environments, consistently delivering high-quality software solutions. I am proficient in creating CI/CD pipelines, ensuring smooth and efficient deployments, and have successfully developed and maintained microservices architectures. This has enabled me to not only build scalable applications but also improve the overall agility and responsiveness of development processes.<br/>" +
      "In addition, I have strong programming skills in <spam style='color: #2ab0ff;'>C#</spam> and a comprehensive understanding of front-end technologies such as <spam style='color: #2ab0ff;'>Angular</spam>, <spam style='color: #2ab0ff;'>ReactJS</spam>, <spam style='color: #2ab0ff;'>JavaScript</spam>, <spam style='color: #2ab0ff;'>CSS</spam>, and <spam style='color: #2ab0ff;'>HTML</spam>. This combination has enabled me to deliver full-stack solutions that meet both technical and business needs. My ability to translate business requirements into functional specifications ensures that I contribute to building products that align with business goals. Additionally, I have some hands-on experience with <spam style='color: #2ab0ff;'>Python</spam>, which has allowed me to work effectively on a variety of back-end tasks, adding more flexibility to my technical skill set. My ability to utilize <spam style='color: #2ab0ff;'>Python</spam> for scripting and automation further strengthens my ability to support DevOps practices and increase operational efficiency.<br/>" +
      "In addition to my technical expertise, I stay up-to-date on the latest technologies, systems, and market trends, which allows me to quickly adapt to new challenges and continually deliver innovative solutions. I am confident that my background, coupled with my enthusiasm for continuous learning and growth, makes me a great fit for your team." +
      "</p>",
  },

  projects: [
    {
      id: "343d262e-c945-474e-bc75-e6d7890aa237",
      name: "Integrated Payment Portal",
    },
    {
      id: "13bf93cd-df03-492c-8e55-60ac38f5071c",
      name: "TopSky Tower",
    },
    {
      id: "5e73cb7f-13ea-41d6-9927-05a234576d7e",
      name: "DSI Business Inteligence",
    },
    {
      id: "ce30ca48-2965-4ceb-8289-cb89e35fe35c",
      name: "New Budget",
    },
    {
      id: "ad3564ae-255f-400a-863f-312af7ab4e45",
      name: "Simulator for Housing Credit",
    },
    {
      id: "db42ca90-ef00-4ab0-be4a-3d24d9c730f9",
      name: "SSIS (SmartPlant Materials & SAP Integration System)",
    },
    {
      id: "f6fcc159-c8a5-4275-82b9-651a7296b184",
      name: "Warehouse+ (Material Control Automation System in the Warehouse)",
    },
    {
      id: "752080fe-3394-449d-aa40-033320242c78",
      name: "e-RIR (Inspection and Receipt Electronic Report)",
    },
    {
      id: "f6a3b64a-d3bc-4b3f-8b0a-0e5629191a36",
      name: "LASA Wave e-Label",
    },
  ],

  skills: [
    { name: "Analytic Problem Solving", versions: "", image: "problem-solving-ability.png", link: "" },
    { name: "Analytical Skills", versions: "", image: "analytical-skills.png", link: "" },
    { name: "AngularJS", versions: "", image: "angular.svg", link: "" },
    { name: "Application Programming Interfaces (API)", versions: "", image: "api.png", link: "" },
    { name: "Architectural Design", versions: "", image: "architectural-design.png", link: "" },
    { name: "ASPDotNet", versions: "", image: "asp-net.png", link: "" },
    { name: "Business Intelligence (BI)", versions: "", image: "bi.png", link: "" },
    { name: "Cascading Style Sheets (CSS)", versions: "", image: "css.svg", link: "" },
    { name: "Confluence", versions: "", image: "confluence.jpg", link: "" },
    { name: "Creative Problem Solving", versions: "", image: "creative-problem-solving.png", link: "" },
    { name: "Crucible", versions: "", image: "crucible.svg", link: "" },
    { name: "Data Structures", versions: "", image: "data_structure.png", link: "" },
    { name: "Decision-Making", versions: "", image: "decision-making.webp", link: "" },
    { name: "Delphi", versions: "7", image: "delphi.png", link: "" },
    { name: "Design Patterns", versions: "", image: "design-patterns.png", link: "" },
    { name: "Domain-Driven Design (DDD)", versions: "", image: "ddd.jpg", link: "" },
    { name: "DotNet CORE (C#)", versions: "", image: "csharp.svg", link: "dotnet+core+with+C%23" },
    { name: "DotNet Framework (C# and VBDotNet)", versions: "", image: "visual-studio.svg", link: "" },
    { name: "Enterprise Softwares", versions: "", image: "enterprise-softwares.png", link: "" },
    { name: "Entity Framework", versions: "4, 5, and 6", image: "ef.png", link: "" },
    { name: "Extract, Transform, Load (ETL)", versions: "", image: "etl.png", link: "" },
    { name: "Git", versions: "", image: "git.svg", link: "" },
    { name: "GitHub", versions: "", image: "github_light.svg", link: "" },
    { name: "Gitlab", versions: "", image: "gitlab.svg", link: "" },
    { name: "HTML 5", versions: "", image: "html5.svg", link: "" },
    { name: "Interbase", versions: "", image: "interbase.webp", link: "" },
    { name: "Intraweb", versions: "", image: "intraweb.png", link: "" },
    { name: "Java", versions: "6, 11", image: "java.svg", link: "" },
    { name: "JavaScript", versions: "", image: "javascript.svg", link: "" },
    { name: "JavaServer Pages (JSP)", versions: "", image: "jsp.png", link: "" },
    { name: "Jenkins", versions: "", image: "jenkins.png", link: "" },
    { name: "Jira", versions: "", image: "jira.webp", link: "" },
    { name: "JSON", versions: "", image: "json-schema.svg", link: "" },
    { name: "LINQ", versions: "", image: "linq.webp", link: "" },
    { name: "Kendo UI", versions: "", image: "kendo-ui.webp", link: "" },
    { name: "Maven", versions: "", image: "maven.jpg", link: "" },
    { name: "Ms Access", versions: "", image: "microsoft.svg", link: "" },
    { name: "Ms Project", versions: "", image: "microsoft.svg", link: "" },
    { name: "Mockito", versions: "", image: "mockito.png", link: "" },
    { name: "Model-View-Controller (MVC)", versions: "", image: "mvc.png", link: "" },
    { name: "MySQL", versions: "", image: "mysql.svg", link: "" },
    { name: "Node.js", versions: "", image: "nodejs.svg", link: "" },
    { name: "Oracle", versions: "", image: "oracle.png", link: "" },
    { name: "PHP", versions: "", image: "php.svg", link: "" },
    { name: "PL/SQL", versions: "", image: "oracle.png", link: "" },
    { name: "Problem Solving", versions: "", image: "problem-solving.png", link: "" },
    { name: "Quality Assurance", versions: "", image: "qa.png", link: "" },
    { name: "ReactJS", versions: "", image: "react.svg", link: "" },
    { name: "Scrum", versions: "", image: "scrum.png", link: "" },
    { name: "Servlets", versions: "", image: "java.svg", link: "" },
    { name: "Shell Script", versions: "", image: "terminal.png", link: "" },
    { name: "SonarQube", versions: "", image: "sonarqube.png", link: "" },
    { name: "Sourcetree", versions: "", image: "sourcetree.svg", link: "" },
    { name: "SQL DB2", versions: "", image: "db2.svg", link: "" },
    { name: "SQL Server", versions: "2000, 2005, 2008, 2012", image: "sql-server.svg", link: "" },
    { name: "Integration Services (SSIS)", versions: "", image: "sql-server.svg", link: "" },
    { name: "SQL Server Reporting Services (SSRS)", versions: "", image: "sql-server.svg", link: "" },
    { name: "Team Foundation Server (TFS)", versions: "", image: "microsoft.svg", link: "" },
    { name: "Test-Driven Development (TDD)", versions: "", image: "tdd.png", link: "" },
    { name: "TIBCO", versions: "", image: "tibco.png", link: "" },
    { name: "TIBCO Spotfire", versions: "", image: "tibco.png", link: "" },
    { name: "Procedures", versions: "", image: "stored-procedure.png", link: "" },
    { name: "Web API", versions: "", image: "api.png", link: "" },
    { name: "Web Applications", versions: "", image: "code.png", link: "" },
    { name: "Web Services", versions: "", image: "web-services.png", link: "" },
    { name: "Web Services API", versions: "", image: "api.png", link: "" },
    { name: "Window Forms", versions: "", image: "code.png", link: "" },
    { name: "Windows Presentation Foundation (WPF)", versions: "", image: "wpf.png", link: "" },
    { name: "Development", versions: "", image: "vscode.svg", link: "" },
    { name: "Write SQL Scripts", versions: "", image: "sql.png", link: "" },
    { name: "Zebra Programming Language (ZPL)", versions: "", image: "zebra.webp", link: "" },
  ],
};

export default enCA;