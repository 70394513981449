/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Global, css } from '@emotion/react';
import HomePage from './pages/HomePage';
import backgroundImage from './assets/background.png';
import { LanguageProvider } from './contexts/LanguageContext/LanguageContext';
import LanguageSwitcher from './components/LanguageSwitcher/LanguageSwitcher';

const App = () => {
  return (
    <>
      <Global
        styles={css`
          html {
            height: 100%;
            background: url(${backgroundImage}) no-repeat center center fixed;
            background-size: cover;
          }
          body {
            margin: 0;
            height: 100%;
            font-family: Arial, Helvetica, sans-serif;
          }
          /* CSS para ocultar o scroll */
          body {
            overflow: hidden; /* Impede o scroll da página */
          }
          #root {
            height: 100%;
          }
        `}
      />
      <LanguageProvider>
        <LanguageSwitcher />
        <HomePage />
    </LanguageProvider>
    </>
  );
};

export default App;